//背景色
$bg-color: #f7f8fa;
// 主题色
$color-blue: #2962ff;
$color-blue2: #eef2ff;
$color-green: #2cf2d1;
$color-orange: #fdc62e;
$color-grey: #ebedf0; //分割线
$color-white: #fff;
//其他色值
$black-1: #101734;
$black-2: #272e42;
$grey-1: #606269;
$grey-2: #90939d;
$grey-3: #acaeb4;
$grey-4: #eef0f4;
$grey-5: #5e6576;
$grey-6: #b9bcc5;
$grey-7: #d4d7de;
$grey-8: #acaeb4;
$red: #f75a5a;
$red-prompt: #f16272;

$blue-0: #2357e8;
$blue-1: #1685ec;
$blue-2: #6991cd;
$blue-3: #b7caff;
$blue-4: #eaefff;
$gray-bg1: #f0f1f3;
$gray-bg2: #f7f8f9;
$gray-bg3: #fafafa;
$gray-bg4: #eceff3;
$gray-bg5: #e5e5e5;
$gray-bg6: #f7f8fa;
$img-path: '';
%position-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

%text-hide {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
}

%flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

%flex-items-center {
    display: flex;
    align-items: center;
}
%flex-vertical-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
%flex-column {
    display: flex;
    flex-direction: column;
}

%flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin flex-center($row: center, $column: center) {
    display: flex;
    justify-content: $row;
    align-items: $column;
}
@mixin icon-font-size($f) {
    font-size: $f + px;
    line-height: $f + px;
}
@mixin font-size($f, $w: 400) {
    font-family: PingFang SC, PingFangSC-Regular;
    font-size: $f + px;
    font-weight: $w;
}

@mixin font-size-medium($s, $w: 500) {
    font-family: PingFangSC-Medium, PingFang SC;
    font-size: $s + px;
    font-weight: $w;
}
@mixin width-hight($w, $h) {
    width: $w + px;
    height: $h + px;
}
@mixin text-ellipsis($line: 2) {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}
@mixin radius-border($radius, $color: $color-grey) {
    border: 1px solid $color;
    border-radius: $radius + px;
}

@mixin fontSize($f, $w: 400) {
    font-family: 'PingFangSC-Regular,PingFang SC';
    font-size: $f + px;
    font-weight: $w;
}

@mixin fontSizeMedium($f, $w: 400) {
    font-family: 'PingFangSC-Medium,PingFang SC;';
    font-size: $f + px;
    font-weight: $w;
}

@mixin iconfontSize($f) {
    font-size: $f + px;
    line-height: $f + px;
}

@mixin widthHight($w, $h) {
    width: $w + px;
    height: $h + px;
}

//文字几行超出省略
@mixin multiTextHide($num) {
    display: -webkit-box;
    display: -moz-box;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    /* autoprefixer: on */
    -webkit-line-clamp: $num;
    -moz-line-clamp: $num;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin radiusBorder($radius, $color: $txt-gray) {
    border: 1px solid $color;
    border-radius: $radius + px;
}

/*
 * @Author: zhicheng.yao 
 * @Date: 2021-10-19 16:37:30 
 * @Last Modified by: zhicheng.yao
 * @Last Modified time: 2021-11-01 18:44:17
 */
html,
body {
    font-family: 'PingFang SC', 'PingFangSC-Regular', 'PingFangSC-Medium';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
}
ul,
li {
    list-style: none;
    margin: 0;
}
input,
textarea {
    &::-webkit-input-placeholder {
        color: $grey-6;
        font-family: PingFangSC-Regular;
    }
    &::-moz-input-placeholder {
        color: $grey-6;
        font-family: PingFangSC-Regular;
    }
    &::-o-input-placeholder {
        color: $grey-6;
        font-family: PingFangSC-Regular;
    }
}
.iconfont {
    color: $grey-7;

    &:hover,
    &:active {
        color: $grey-6;
    }
}
/*以下为公共的class样式*/

.m-container {
    padding: 24px;
}
.m-div-box {
    background: #ffffff;
    border-radius: 4px;
    padding: 16px 24px;
}
.m-page-title {
    color: $black-1;
    @include font-size(18, 500);
    margin-bottom: 24px;
}
.m-bg-color {
    background: $bg-color;
}
.m-btn-linear {
    background: linear-gradient(135deg, #29a6ff 0%, #2962ff 100%);
}
.m-word-break {
    word-break: break-all;
}
.m-text-hide {
    @extend %text-hide;
}
.white-space-pre {
    white-space: pre-line;
    word-break: break-all;
}
.flex {
    display: flex;
}
.flex-between {
    @extend %flex-between;
}
.flex-items-center {
    @extend %flex-items-center;
}
.flex-vertical-center {
    @extend %flex-vertical-center;
}
.flex-column {
    @extend %flex-column;
}
.flex-column-center {
    @extend %flex-column-center;
}
.position-center {
    @extend %position-center;
}
.click-blue {
    color: $color-blue;
    cursor: pointer;
}
.cursor-pointer {
    cursor: pointer;
}
.mr-6 {
    margin-right: 6px;
}
.ml-6 {
    margin-left: 6px;
}
.mt-8 {
    margin-top: 8px;
}
.mb-8 {
    margin-bottom: 8px;
}
.ml-8 {
    margin-left: 8px;
}
.mr-8 {
    margin-right: 6px;
}
.mt-12 {
    margin-top: 12px;
}
.mr-12 {
    margin-right: 12px;
}
.ml-12 {
    margin-left: 12px;
}
.mb-12 {
    margin-bottom: 12px;
}
.mt-16 {
    margin-top: 16px;
}
.mb-16 {
    margin-bottom: 16px;
}
.mr-16 {
    margin-right: 16px;
}
.ml-16 {
    margin-left: 16px;
}
.mt-24 {
    margin-top: 24px;
}
.mb-24 {
    margin-bottom: 24px;
}
.ml-24 {
    margin-left: 24px;
}
.mr-24 {
    margin-right: 24px;
}
.pl-24 {
    padding-left: 24px;
}
.c-bule {
    color: $color-blue;
}
.c-green {
    color: $color-green;
}
.c-orange {
    color: $color-orange;
}
.c-grey {
    color: $color-grey;
}
.c-d6 {
    color: #d6d6d6;
}
.c-ff {
    color: #ffffff;
}
.c-b1 {
    color: $black-1;
}
.c-b2 {
    color: $black-2;
}
.c-g9 {
    color: $grey-2;
}
.c-5e {
    color: $grey-5;
}
.c-5e-hover {
    color: $grey-5;
    &:hover {
        color: $black-2;
    }
}
.t-center {
    text-align: center;
}
.icon-20 {
    @include icon-font-size(20);
}
.icon-24 {
    @include icon-font-size(24);
}
.weight-400 {
    font-family: PingFang SC, PingFangSC-Regular;
    font-weight: 400;
}
.weight-500 {
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
}
.search-cell {
    margin-right: 24px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}
.search-label {
    color: $black-1;
    @include font-size(14);
}
.form-label {
    @extend .search-label;
    width: 100px;
    text-align: right;
    flex: none;
}
.form-required {
    font-size: 15px;
    color: #f75a5a;
    margin-right: 6px;
    // width: 12px;
    // height: 12px;
    // margin-right: 6px;
    // display: inline-flex;
    // &::before {
    //     content: '*';
    //     color: $red;
    //     width: 12px;
    //     height: 100%;
    // }
}

//详情
.contentBlock {
    padding: 16px 24px;
    background: #ffffff;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 24px;
    @include font-size(14);
    &-title {
        font-weight: 500;
        line-height: 20px;
        color: $black-1;
        margin-bottom: 24px;
    }
    .content-box {
        display: flex;
        flex-wrap: wrap;
    }
    .detail-item {
        width: 33.33%;
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        .labbel {
            width: 100px;
            flex: none;
        }
        .value {
            flex: auto;
            word-break: break-all;
        }
    }
}

.table-avatar {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    object-fit: cover;
}
.radius-50 {
    border-radius: 50%;
}

/*以下为修改的antd样式*/
.ant-checkbox-group-item {
    margin-bottom: 16px;
}
.ant-popover-inner {
    border-radius: 4px;
    &-content {
        padding: 4px 0;
    }
}
.ant-popover-inner-content {
    padding: 4px 0;
}
.preWrapper {
    z-index: 99;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(68, 68, 68, 0.7);
}
.preWrapper .pre-filed-viewBox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transfrom: translate(-50%, -50%);
    background: #ffffff;
}
.echartsTooltip {
    @include font-size(13);
    color: $black-2;
}
